<template>
  <div class="row">
    <div class="col">
      <b-modal
        ref="modalService"
        id="modalService"
        title="Service"
        hide-footer
        size="lg"
        centered
      >
        <div class="card-body">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-end mb-2"
          >
            <b-button
              class="btn-sm m-2"
              variant="success"
              type="submit"
              :to="{ name: 'vehicleform' }"
            >
              <i class="uil-plus"></i>
              ลงทะเบียนรถใหม่
            </b-button>
            <b-button
              v-b-modal.modalAppointment
              class="btn-sm m-2"
              variant="success"
              @click="getDataAppointment"
            >
              <i class="uil-plus"></i>
              ลูกค้านัดหมาย
            </b-button>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-lg-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ทะเบียน:</label>
                <multiselect
                  v-model="vin"
                  label="licensePlate"
                  type="search"
                  :options="rowsService"
                  :show-labels="false"
                  open-direction="bottom"
                  @search-change="getDataService($event)"
                  @remove="removeService"
                  placeholder=""
                  :class="{
                    'is-invalid': submitform && $v.vin.$error,
                  }"
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                  <span v-if="!$v.vin.required">{{ errormessage }}</span>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ชื่อลูกค้า:</label>
                <multiselect
                  v-model="nameCus"
                  label="nameTh"
                  type="search"
                  :options="rowsUser"
                  :show-labels="false"
                  open-direction="bottom"
                  @search-change="getDataCus($event)"
                  @remove="getRemoveSelected"
                  :custom-label="customLabel"
                  placeholder=""
                  :class="{
                    'is-invalid': submitform && $v.vin.$error,
                  }"
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.nameTh }}&nbsp;</span
                      ><span class="option__small">{{
                        props.option.familyNameTh
                      }}</span>
                    </div>
                  </template>
                </multiselect>
                <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                  <span v-if="!$v.vin.required">{{ errormessage }}</span>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-lg-4">
              <div class="mb-3 position-relative">
                <code>* </code
                ><label for="validation">วันที่เข้ารับบริการ:</label>
                <date-picker
                  v-model="svDate"
                  format="YYYY-MM-DD"
                  value-type="format"
                  :class="{
                    'is-invalid': submitform && $v.svDate.$error,
                  }"
                ></date-picker>
                <div
                  v-if="submitform && $v.svDate.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.svDate.required">{{ errormessage }}</span>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ประเภท:</label>
                <div>
                  <multiselect
                    v-model="distance"
                    label="nameTh"
                    :options="rowsMasterSD"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.distance.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.distance.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.distance.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-3">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">เลขตัวถัง:</label>
                <multiselect
                  v-model="vin"
                  label="vin"
                  :options="rowsService"
                  :show-labels="false"
                  :loading="isLoading"
                  :close-on-select="true"
                  :internal-search="true"
                  open-direction="bottom"
                  @search-change="getDataServiceVin($event)"
                  placeholder=""
                  :class="{
                    'is-invalid': submitform && $v.vin.$error,
                  }"
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                  <span v-if="!$v.vin.required">{{ errormessage }}</span>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-3">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">วันที่เสร็จสิ้น:</label>
                <date-picker
                  v-model="expectFinishDate"
                  format="YYYY-MM-DD"
                  value-type="format"
                ></date-picker>
              </div>
            </div>
            <div class="col-4 col-sm-3 col-lg-2">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">เวลาเสร็จสิ้น:</label>
                <date-picker
                  v-model="expectFinishTime"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                >
                </date-picker>
              </div>
            </div>
          </div>

          <div class="row"></div>

          <div class="row">
            <div class="text-end">
              <b-button
                class="btn btn-primary"
                variant="primary"
                @click="tooltipForm()"
              >
                บันทึก
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="modalAppointment"
        id="modalAppointment"
        title="ลูกค้านัดหมาย"
        hide-footer
        size="xl"
        centered
      >
        <div class="card-body">
          <span
            v-if="this.rowsAppointment.total < 1"
            class="d-flex justify-content-center"
            >ไม่มีรายการลูกค้านัดหมายวันนี้</span
          >
          <div v-if="this.rowsAppointment.total > 0">
            <div class="table-responsive mb-0">
              <b-table
                :items="rowsAppointment"
                :fields="fieldsApp"
                responsive="sm"
                ref="selectableTable"
                selectable
                @row-selected="onRowSelectedAppointment"
              >
                <template #cell(index)="rowsAppointment">
                  {{ rowsAppointment.index + 1 }}
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div class="align-items-center">
                  แสดงผล {{ perPageAppointment }} หน้า
                  {{ currentPageAppointment }} จาก
                  {{ this.rowsAppointment.total }} รายการ
                </div>
              </div>
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPageAppointment"
                      :total-rows="rowsAppointment.total"
                      :per-page="perPageAppointment"
                      @change="handleChangePageAppointment"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="modalServiceFast"
        id="modalServiceFast"
        title="เปิด Service แบบด่วน"
        hide-footer
        centered
      >
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-sm-3 col-lg-3">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">อักษรหน้า:</label>
                <input
                  v-model="platePrefixNumber"
                  type="text"
                  class="form-control"
                  @keyup.enter="searchPlate"
                  maxlength="3"
                />
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-3">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">เลขป้าย:</label>
                <input
                  v-model="plateNumber"
                  type="text"
                  class="form-control"
                  maxlength="4"
                  @keyup.enter="searchPlate"
                />
                <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                  <span v-if="!$v.vin.required">{{ errormessage }}</span>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-5 col-lg-6">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">จังหวัด:</label>
                <multiselect
                  v-model="plateProvinceId"
                  label="nameTh"
                  :options="rowsProvince"
                  :show-labels="false"
                  @input="searchPlate"
                  open-direction="bottom"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <!-- <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                <span v-if="!$v.vin.required">{{ errormessage }}</span>

                                :class="{
                  'is-invalid': submitform && $v.vin.$error,
                }"
              </div> -->
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ชื่อลูกค้า:</label>
                <input
                  v-model="name"
                  type="text"
                  class="form-control"
                  maxlength="30"
                  @keyup.enter="handleSearch"
                />
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-4">
              <div class="mb-3 position-relative">
                <label for="validation">นามสกุล:</label>
                <input
                  v-model="familyName"
                  type="text"
                  class="form-control"
                  maxlength="30"
                  @keyup.enter="handleSearch"
                />
              </div>
            </div>
            <div class="col-8 col-sm-4 col-lg-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">เบอร์โทรศัพท์:</label>
                <input
                  v-model="mobilePhone"
                  type="text"
                  class="form-control"
                  maxlength="10"
                  @keyup.enter="handleSearch"
                />
              </div>
            </div>
            <div class="col-8 col-sm-4 col-lg-5">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ยี่ห้อ:</label>
                <multiselect
                  v-model="brandId"
                  label="nameEn"
                  :options="masterBrand"
                  :show-labels="false"
                  @input="getSelectMasterModel"
                  open-direction="bottom"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
              </div>
            </div>
            <div class="col-8 col-sm-4 col-lg-7">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">รุ่น:</label>
                <multiselect
                  v-model="modelId"
                  label="nameEn"
                  :options="masterModel"
                  :show-labels="false"
                  open-direction="bottom"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
              </div>
            </div>
            <div class="col-8 col-sm-4 col-lg-6">
              <div class="mb-3 position-relative">
                <code>* </code><label for="validation">ประเภท:</label>
                <multiselect
                    v-model="distancefast"
                    label="nameTh"
                    :options="rowsMasterSD"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.distance.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
              </div>
            </div>
          </div>

          <div class="row"></div>

          <div class="row">
            <div class="text-end">
              <b-button
                class="btn btn-primary"
                variant="primary"
                @click="submitInternal()"
              >
                บันทึก
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
    components: { Multiselect, DatePicker },
  data() {
    return {
      isLoading: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      userBranch: "",
      apptId: "",
      rowsSelect: [],
      service: [],
      rowsAppointment: [],
      rowsAppointmentDetail: [],
      svId: "",
      hasGsJob: 0,
      hasBpJob: 0,
      hasWcJob: 0,
      brandId: "",
      modelId: "",
      distancefast: "",
      apptDate: "",
      nameCus: "",
      vin: "",
      svDate: "",
      type: "",
      disTance: "",
      insurance: "",
      warranty: "",
      requestType: "",
      licensePlate: "",
      masterBrand: [],
      masterModel: [],
      selected: [],
      selectedAppointment: "",
      submitform: false,
      filter: {
        vin: "",
        licensePlate: "",
        mobilePhone: "",
      },
      rows: [],
      rowsService: [],
      rowsUser: [],
      rowsCusfull: "",
      sortBy: "age",
      sortDesc: false,
      fieldsApp: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "apptId",
          label: "appId",
        },
        {
          key: "apptCode",
          label: "รหัสนัดหมาย",
        },
        {
          key: "customerName",
          label: "ชื่อลูกค้านัดหมาย",
        },
        {
          key: "customerMobilePhone",
          label: "เบอร์โทรศัพท์",
        },
        {
          key: "apptTime",
          label: "เวลานัดหมาย",
        },
        {
          key: "vehiclePlate",
          label: "ป้ายทะเบียน",
        },
        {
          key: "vehicleModelEn",
          label: "รุ่น",
        },
      ],
      overlayFlag: false,
      rowsMasterSD: [],
      distance: "",
      expectFinishDate: "",
      expectFinishTime: "",

      currentPage: 1,
      currentPageAppointment: 1,
      perPage: 10,
      perPageAppointment: 10,

      platePrefixNumber: "",
      plateNumber: "",
      plateProvinceId: "",
      mobilePhone: "",
      name: "",
      familyName: "",
      rowsProvince: [],
      rowsPlate: [],
      userBranchId: ""
    };
  },
  validations: {
    vin: {
      required,
    },
    svDate: {
      required,
    },
    distance: {
      required,
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.userBranchId = user.branchId;
    this.totalRows = this.rows.total;
    this.totalRows = this.rowsAppointment.total;
    this.getSelectMasterBrand();
  },
  created() {
    this.getDataServiceDistances();
    this.getDataProvince();
  },
  methods: {
    getData: function () {
      this.overlayFlag = true;
      this.isLoading = true;
      useNetw
        .get("api/service", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranch,
            vin: this.filter.vin,
            licensePlate: this.filter.licensePlate,
            mobilePhone: this.filter.mobilePhone,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.to = response.data.to;
          this.rows.from = response.data.from;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    getSelectMasterBrand: function() {
      useNetw
        .get("/api/master/vehicle/brands", {})
        .then((response) => {
          this.masterBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterModel: function(isReset) {
      if(this.brandId != null) {
      useNetw
        .get("/api/master/vehicle/models", {
          params: {
            branchId: this.userBranchId,
            brandId: this.brandId.brandId,
          },
        })
        .then((response) => {
          this.masterModel = response.data.data;
          if (isReset) {
            this.modelId = null;
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          if (isReset) {
            this.brandId = null;
            this.modelId = null;
          }
        })
        .then(() => {});
        }
    },
    getDataService: function (license) {
      this.isLoading = true;
      useNetw
        .get("api/service/vehicle", {
          params: {
            page: this.currentPage,
            perPage: 100,
            licensePlate: license,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsService = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getDataServiceVin: function (vin) {
      this.isLoading = true;
      useNetw
        .get("api/service/vehicle", {
          params: {
            page: this.currentPage,
            perPage: 100,
            vin: vin,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsService = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    removeService: function () {
      this.isLoading = true;
      useNetw
        .get("api/service/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsService = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getRemoveSelected: function () {
      this.isLoading = true;
      useNetw
        .get("api/service/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteVehicle(rows) {
      this.vehicleId = rows;
      this.overlayFlag = true;
      useNetw
        .delete("api/vehicle/delete", {
          data: {
            vehicleId: this.vehicleId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh != null ? familyNameTh : ''}`;
    },
    clearAll() {
      this.vin = [];
    },
    getDataCus: function (name) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/customer", {
          params: {
            page: 1,
            perPage: 100,
            nameTh: name,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    submitService: function () {
      const bodyFormData = new FormData();
      bodyFormData.append("branchId", this.userBranchId);
      bodyFormData.append("cusId", this.nameCus.cusId);
      bodyFormData.append("vehicleId", this.vin.vehicleId);
      //   bodyFormData.append("vin", this.vin.vin);
      bodyFormData.append("svDate", this.svDate);
      bodyFormData.append("distanceId", this.distance.distanceId);
      bodyFormData.append("expectFinishDate", this.expectFinishDate);
      bodyFormData.append("expectFinishTime", this.expectFinishTime);
      bodyFormData.append("requestType", "PRE");
      useNetw
        .post("/api/service/store", bodyFormData)
        .then((response) => {
          // this.svId = response.data.svId;
          var encodedString = btoa(response.data.svId);
          this.svIdDecode = encodedString
          // console.log(response)
          if (response.data.requiredConfirm != true) {
            Swal.fire(
              appConfig.swal.title.postSuccess,
              JSON.stringify(response.data.message),
              appConfig.swal.type.success
            );
            this.$router.push({
              name: "checkmycar",
              params: { svId: this.svIdDecode, service: this.$route.params.service },
            });
            this.vin = "";
            this.svDate = "";
            this.licensePlate = "";
            this.vin = "";
            this.hasGsJob = 0;
            this.hasBpJob = 0;
            this.hasWcJob = 0;
          } else {
            this.alert(response);
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalService.hide();
        });
    },
    submitServiceConfirm: function () {
      const bodyFormData = new FormData();
      bodyFormData.append("branchId", this.userBranchId);
      bodyFormData.append("cusId", this.nameCus.cusId);
      bodyFormData.append("vehicleId", this.vin.vehicleId);
      //   bodyFormData.append("vin", this.vin.vin);
      bodyFormData.append("svDate", this.svDate);
      bodyFormData.append("distanceId", this.distance.distanceId);
      bodyFormData.append("expectFinishDate", this.expectFinishDate);
      bodyFormData.append("expectFinishTime", this.expectFinishTime);
      bodyFormData.append("requestType", "CRE");
      useNetw
        .post("/api/service/store", bodyFormData)
        .then((response) => {
          // this.svId = response.data.svId;
          var encodedString = btoa(response.data.svId);
          this.svIdCreDecode = encodedString
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "editCheckMycar",
            params: { svId: this.svIdCreDecode, service: this.service },
          });
        })
        .catch((err) => {
          //   Swal.fire(
          //     appConfig.swal.title.error,
          //     JSON.stringify(err.response.data.message),
          //     console.log(this.type.typeId),
          //     appConfig.swal.type.error
          //   );
          this.alert(err);
        })
        .then(() => {
          // console.log(this.svId)
          // this.$route.push({name: 'checkmycar', params: {svId: this.svId}})
        });
    },
    alert(response) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: JSON.stringify(response.data.message),
          text: "ต้องการทำรายการหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.submitServiceConfirm();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
          this.vin = "";
          this.svDate = "";
          this.licensePlate = "";
          this.vin = "";
          this.hasGsJob = 0;
          this.hasBpJob = 0;
          this.hasWcJob = 0;
        });
    },
    getDataServiceDistances: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/master/service-distances", {})
        .then((response) => {
          this.rowsMasterSD = response.data.data;
          // console.log(this.rowsTest.total);
          // console.log(this.rowsMasterSD);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitService();
      }
    },

    getDataAppointment: function () {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      this.apptDate = year + "-" + month + "-" + day;
      this.loading = true; //skeleton true
      useNetw
        .get("api/appointment", {
          params: {
            page: this.currentPageAppointment,
            perPage: 100, // this.perPageAppointment,
            branchId: this.userBranch,
            apptDate: this.apptDate,
          },
        })
        .then((response) => {
          this.rowsAppointment = response.data.data;
          this.rowsAppointment.total = response.data.total;
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    handleChangePageAppointment(page) {
      this.currentPageAppointment = page;
      this.getDataAppointment();
    },
    handlePageChangeAppointment(active) {
      this.perPageAppointment = active;
      this.currentPageAppointment = 1;
      this.getDataAppointment();
    },
    onRowSelectedAppointment(rows) {
      this.selectedAppointment = rows;

      const arrSelected = [];
      this.selectedAppointment.forEach((item) => {
        arrSelected.push(item.apptId);
      });

      this.apptId = arrSelected;
      // console.log(arrSelected);
      this.$refs.modalAppointment.hide();
      this.getDataAppointmentSelect();
    },
    selectAllRowsAppointment() {
      this.$refs.selectableTable.selectAllRowsAppointment();
    },
    clearSelectedAppointment() {
      this.$refs.selectableTable.clearSelectedAppointment();
    },

    getDataAppointmentSelect: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/appointment/show", {
          params: {
            apptId: this.apptId.toString(),
          },
        })
        .then((response) => {
          this.rowsAppointmentDetail = response.data.branchAppointment;
          this.vin = {
            licensePlate: response.data.branchAppointment.vehiclePlate,
            ownerNameTh: response.data.branchAppointment.cusName,
            vin: response.data.branchAppointment.vin,
            cusId: response.data.branchAppointment.cusId,
            vehicleId: response.data.branchAppointment.vehicleId,
          };
          this.nameCus = {
            nameTh: response.data.branchAppointment.cusName,
            cusId: response.data.branchAppointment.cusId,
          };
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataProvince(){
      useNetw
        .get("api/master/address/provinces", {
        })
        .then((response) => {
          this.rowsProvince = response.data.data;
          // console.log(this.rowsProvince)
        })
        .catch((err) => {
            Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    searchPlate() {
      useNetw
        .get("api/service/check-license-plate", {
          params: {
            // branchId: JSON.stringify(this.userBranch),
            branchId: this.userBranchId,
            platePrefixNumber: this.platePrefixNumber,
            plateNumber: this.plateNumber,
            plateProvinceId: this.plateProvinceId.provinceId
          }
        })
        .then((response) => {
          this.rowsPlate = response.data.data;
          this.name = response.data.data.ownerNameTH
          this.familyName = response.data.data.ownerFamilyNameTH
          this.mobilePhone = response.data.data.ownerMobilePhone
          this.brandId = {brandId: response.data.data.brandId, nameEn: response.data.data.brandNameEn}
          this.modelId = {modelId: response.data.data.modelId, nameEn: response.data.data.modelNameEn}
          // console.log(this.rowsPlate)
        })
        .catch((err) => {
          if (err.response.status != 404 && err.response.status != 400) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        // .catch((err) => {
        //     Swal.fire(
        //     appConfig.swal.title.error,
        //     JSON.stringify(err.response.data.message),
        //     appConfig.swal.type.error
        //   );
        // })
        // .then(() => {
        //   this.isLoading = false;
        // });
    },
    submitInternal() {
      const bodyFormData = new FormData();
      bodyFormData.append("branchId", this.userBranch[0]);
      bodyFormData.append("platePrefixNumber", this.platePrefixNumber);
      bodyFormData.append("plateNumber", this.plateNumber);
      bodyFormData.append("plateProvinceId", this.plateProvinceId.provinceId);
      bodyFormData.append("name", this.name);
      bodyFormData.append("familyName", this.familyName);
      bodyFormData.append("mobilePhone", this.mobilePhone);
      if(this.brandId.brandId != null) {
        bodyFormData.append("brandId", this.brandId.brandId);
      }
      if(this.modelId.modelId != null) {
      bodyFormData.append("modelId", this.modelId.modelId);
      }
      if(this.distancefast.distanceId != null) {
      bodyFormData.append("distanceId", this.distancefast.distanceId);
      }
      useNetw
        .post("api/service/internal-store", bodyFormData, {
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.platePrefixNumber = null
          this.plateNumber = null
          this.plateProvinceId = ''
          this.name = null
          this.familyName = null
          this.mobilePhone = null

          this.$refs.modalService.hide();
          this.$refs.modalServiceFast.hide();
          this.getData();
          this.$router.push({
            name: "editCheckMycar",
            params: { svId: btoa(response.data.svId) },
          });
        })
        .catch((err) => {
            Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          
        });
    }
  },
};
</script>

<style></style>
